<div class="login__forms" [class.is-blurred]="popupDebitCardInfo.show || showNFTPopup || iframePopup.show || verifyPopup.show || isTermsExpandedShown || showLibraryBigImagePopup">
    <div class="login__tab">
        <ng-container *ngIf="showAdditionContent()">
            <app-balance-heading></app-balance-heading>
        </ng-container>


        <div class="page-block" [class.page-block--no-mt]="!showAdditionContent()">
            <div class="gl-page-title gl-page-title--icon c-accent" *ngIf="step !== pageSteps.generation">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
                <ng-container *ngIf="step !== pageSteps.spinner">
                    {{'createYourNFT' | languageTranslate}}
                </ng-container>
                <ng-container *ngIf="step === pageSteps.spinner">
                    {{'checkoutSpinner' | languageTranslate: [{$innerVariable: 'title'}]}}
                </ng-container>
            </div>

            <ng-container *ngIf="step === pageSteps.initial && !showMint">
                <span class="gl-subtitle gl-subtitle--opacity">
                    {{'checkoutPageStepsTitles' | languageTranslate: [{$innerVariable: 'initial'}]}}
                </span>
                <ng-container *ngIf="showAdditionContent()">
                        <span class="gl-subtitle gl-subtitle--opacity accent" style="opacity: 1">
                            {{'checkoutPageStepsTitles' | languageTranslate: [{$innerVariable: 'initialAddition'}]}}
                        </span>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="step === pageSteps.typeSelection">
                <span class="gl-subtitle gl-subtitle--opacity">
                    {{'checkoutPageStepsTitles' | languageTranslate: [{$innerVariable: 'typeSelection'}]}}
                </span>
                <ng-container *ngIf="showAdditionContent()">
                    <span class="gl-subtitle gl-subtitle--opacity accent" style="opacity: 1">
                        {{'checkoutPageStepsTitles' | languageTranslate: [{$innerVariable: 'typeSelectionAddition'}]}}
                    </span>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="step === pageSteps.settings">
                <span class="gl-subtitle gl-subtitle--opacity">
                    {{'checkoutPageStepsTitles' | languageTranslate: [{$innerVariable: 'settings'}]}}
                </span>
                <ng-container *ngIf="showAdditionContent()">
                    <span class="gl-subtitle gl-subtitle--opacity accent" style="opacity: 1">
                        {{'checkoutPageStepsTitles' | languageTranslate: [{$innerVariable: 'settingsAddition'}]}}
                    </span>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="step === pageSteps.terms">
                <span class="gl-subtitle gl-subtitle--opacity">
                    {{'checkoutPageStepsTitles' | languageTranslate: [{$innerVariable: 'terms'}]}}
                </span>
                <span *ngIf="showAdditionContent()" class="gl-subtitle gl-subtitle--opacity accent" style="opacity: 1">{{'checkoutPageStepsTitles' | languageTranslate: [{$innerVariable: 'termsAddition'}]}}</span>
                <span class="gl-subtitle gl-subtitle--opacity">.</span>
            </ng-container>

            <div class="gl-subtitle gl-subtitle--opacity">
                <ng-container *ngIf="step === pageSteps.initial && showMint">
                    {{'checkoutPageStepsTitles' | languageTranslate: [{$innerVariable: 'mint'}]}}
                    {{'pleaseSelect' | languageTranslate}}
                    {{mintAmount}} {{ mintAmount > 1 ? getLanguageString('images') : getLanguageString('images')}}
                    {{'belowBeforeProceeding' | languageTranslate}}.
                </ng-container>
                <ng-container *ngIf="step === pageSteps.spinner">
                    {{'checkoutSpinner' | languageTranslate: [{$innerVariable: 'text'}]}}
                </ng-container>
            </div>

            <ng-container *ngIf="step === pageSteps.initial">
                <ng-container *ngIf="!showMint">
                    <div class="balance-heading">
                        <div class="balance-heading__col">
                            <div class="balance-heading__row">
                                <div class="gl-text">
                                    {{'ai' | languageTranslate}} {{'units' | languageTranslate}}:
                                </div>
                                <div class="gl-text">{{aiUnits}} {{'units' | languageTranslate}}</div>
                            </div>
                            <div class="balance-heading__row" *ngIf="showAdditionContent()">
                                <div class="gl-text">
                                    {{'sweepsEntries' | languageTranslate}}:
                                </div>
                                <div class="gl-text">{{formatCurrency(unitsCost)}}</div>
                            </div>
                        </div>
                    </div>
                    <ul class="units-selection" *ngIf="step === 1">
                        <li class="units-selection__item">
                            <button class="units-selection__btn"
                                    [disabled]="isUnfinished"
                                    (click)="onUpdateUnits(5)">5</button>
                        </li>
                        <li class="units-selection__item">
                            <button class="units-selection__btn"
                                    [disabled]="isUnfinished"
                                    (click)="onUpdateUnits(10)">10</button>
                        </li>
                        <li class="units-selection__item">
                            <button class="units-selection__btn"
                                    [disabled]="isUnfinished"
                                    (click)="onUpdateUnits(20)">20</button>
                        </li>
                        <li class="units-selection__item">
                            <button class="units-selection__btn"
                                    [disabled]="isUnfinished"
                                    (click)="onUpdateUnits(50)">50</button>
                        </li>
                        <li class="units-selection__item">
                            <button class="units-selection__btn"
                                    [disabled]="isUnfinished"
                                    (click)="onUpdateUnits()">
                                <img src="../../../assets/images/clear-symbol.png" alt="">
                            </button>
                        </li>
                    </ul>
                    <div>
                        <ul class="primary-list gl-subtitle gl-subtitle--opacity">
                            <li>{{'collectionOf' | languageTranslate}} {{aiUnits}} {{'ai' | languageTranslate}} {{'images' | languageTranslate | titlecase}}</li>
                            <li *ngIf="showAdditionContent()" class="accent">
                                {{formatCurrency(unitsCost)}} {{'in' | languageTranslate}} {{'sweepsEntries' | languageTranslate}}
                            </li>
                        </ul>
                        <ul class="accent-list gl-subtitle gl-subtitle--opacity"
                            *ngIf="showAdditionContent() && games.length">
                            <li *ngFor="let game of games">{{game.name}}</li>
                        </ul>
                    </div>
                </ng-container>
                <ng-container *ngIf="showMint">
                    <div class="mint-pictures">
                        <ul class="brushes brushes--no-padding">
                            <li class="brushes__item mint-pictures__item" *ngFor="let item of mintBrushes; let i = index">
                                <img [src]="item.image_link ? item.image_link : item.image_full"
                                     alt=""
                                     class="brushes__img">
                                <div class="nft-terms-agreement mint-pictures__checkbox">
                                    <input [id]="'mintBrush'+i" (change)="onMintBrushChange($event, item)" class="nft-terms-agreement__input checkbox-primary" type="checkbox">
                                    <label class="nft-terms-agreement__label" [for]="'mintBrush'+i" style="cursor: pointer">
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="checkout-btns checkout-btns--row">
                        <button class="button button-mint"
                                [disabled]="mintAmount !== mintBrushesSelected.length"
                                (click)="onMint()">
                            {{'mint' | languageTranslate}}
                        </button>
                        <button class="button"
                                [disabled]="mintBrushes.length === mintBrushesTotal"
                                (click)="showMore()">
                            {{'showMore' | languageTranslate}}
                        </button>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="(step === pageSteps.settings) || (step === pageSteps.generation)">
                <ng-container *ngIf="step === pageSteps.settings">
                    <ul class="brushes brushes--named">
                        <li class="brushes__item" *ngFor="let item of brushes"
                            (click)="onChangeSelectedBrush(item.id)"
                            [class.is-active]="selectedBrush === item.id">
                            <div class="brushes__item-name">{{item.name}}</div>
                            <img [src]="item.image_link"
                                 alt=""
                                 class="brushes__img">
                        </li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="step === pageSteps.generation">
                    <div class="pic-generation">
                        <div class="pic-generation__img-wrapper">
                            <img [src]="generateDefaultImage"
                                 class="pic-generation__img"
                                 alt="">
                        </div>
                    </div>
                </ng-container>

                <div class="checkout-heading" *ngIf="!generationIsFinished">
                    {{'enter' | languageTranslate}} {{'keywords' | languageTranslate}}
                </div>
            </ng-container>



            <form [formGroup]="checkOutForm" class="login__form">
                <ng-container *ngIf="step === pageSteps.terms">
                    <div class="checkout-terms">
                        <div class="checkout-terms__title">
                            {{'aiTermsAndConditions' | languageTranslate}}
                        </div>

                        <div class="checkout-terms__text">
                            <div class="nft-terms" [innerHTML]="terms"></div>
                        </div>

                        <div style="text-align: center; box-shadow: 0 -1rem 1rem 1rem #400000; position: relative;">
                            <button class="button"
                                    (click)="showCheckoutTerms()">
                                {{'expand' | languageTranslate}}
                            </button>
                        </div>
                    </div>
                    <div class="nft-terms-agreement" style="margin-bottom: 2rem">
                        <input #termsAccept
                               id="termsAccept"
                               formControlName="terms"
                               class="nft-terms-agreement__input checkbox-primary"
                               type="checkbox">
                        <label class="nft-terms-agreement__label" for="termsAccept" style="cursor: pointer">
                            <!--                        I Accept-->
                            {{'iAgreeToTermsAndConditions' | languageTranslate}}
                        </label>
                    </div>
                    <div *ngIf="termsAccept.checked">
                        <ul class="primary-list gl-subtitle gl-subtitle--opacity">
                            <li>{{'collectionOf' | languageTranslate}} {{aiUnits}} {{'ai' | languageTranslate}} {{'images' | languageTranslate | titlecase}}</li>
                            <li *ngIf="showAdditionContent()" class="accent">
                                {{formatCurrency(unitsCost)}} {{'in' | languageTranslate}} {{'sweepsEntries' | languageTranslate}}
                            </li>
                        </ul>
                        <ul class="accent-list gl-subtitle gl-subtitle--opacity"
                            *ngIf="showAdditionContent() && games.length">
                            <li *ngFor="let game of games">{{game.name}}</li>
                        </ul>

                        <div style="margin-bottom: 2rem">
                            <div class="gl-subtitle"
                                 *ngIf="isGenerateAll()"
                                 style="opacity: 1">
                                {{'generateAllText' | languageTranslate}}
                                <ng-container *ngIf="showAdditionContent()">
                                    <span class="accent">
                                        {{'generateAllTextAddition' | languageTranslate}}
                                    </span>
                                </ng-container>
                            </div>

                            <ng-container *ngIf="!isGenerateAll()">
                                <div class="gl-subtitle"
                                     style="opacity: 1">
                                    {{'generateManuallyText' | languageTranslate}}
                                    <ng-container *ngIf="showAdditionContent()">
                                    <span class="accent">
                                        {{'generateManuallyTextContentAddition' | languageTranslate}}
                                    </span>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="showAdditionContent()">
                                    <div class="gl-subtitle accent"
                                         style="text-transform: uppercase; opacity: 1">
                                        {{'generateManuallyTextAddition' | languageTranslate}}
                                    </div>
                                    <button class="button"
                                            (click)="resetToGenerateAll()"
                                            [disabled]="isGenerateAll()"
                                            style="min-width: 4.5rem; width: 4.5rem">{{'yes' | languageTranslate}} </button>
                                </ng-container>
                            </ng-container>

                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="step === 2">
                    <div class="checkout-heading" style="margin-bottom: 1rem">
                        {{'howDoYouWantToProceed' | languageTranslate}}
                    </div>

                    <div class="checkout-radio-btns">
                        <div class="checkout-radio-btns__item">
                            <input type="radio"
                                   formControlName="generation"
                                   id="instant"
                                   name="generation"
                                   class="checkout-radio-btns__input"
                                   value="instant"  checked/>
                            <label for="instant" class="checkout-radio-btns__label">
                                <span>{{'instant' | languageTranslate}} {{'generation' | languageTranslate}}</span>
                                <span>
                                    <ng-container *ngIf="showAdditionContent()">
                                        <span class="accent">
                                            {{'freeSweeps' | languageTranslate}} {{'now' | languageTranslate | titlecase}}:
                                        </span>
                                    </ng-container>
                                    {{'typeSelection' | languageTranslate: [{$innerVariable: 'instant'}]}}
                                    <ng-container *ngIf="showAdditionContent()">
                                        <span class="accent">
                                            {{'typeSelection' | languageTranslate: [{$innerVariable: 'instantAddition'}]}}
                                        </span>
                                    </ng-container>
                                    {{'nftPopupData' | languageTranslate: [{$innerVariable: 'allSalesAreFinal'}]}}
                                </span>
                            </label>
                        </div>

                        <div class="checkout-radio-btns__item">
                            <input type="radio"
                                   formControlName="generation"
                                   id="manual"
                                   name="generation"
                                   class="checkout-radio-btns__input"
                                   value="manual" />
                            <label for="manual" class="checkout-radio-btns__label">
                                <span>{{'manual' | languageTranslate}} {{'generation' | languageTranslate}}</span>
                                <span>
                                    <ng-container *ngIf="showAdditionContent()">
                                        <span class="accent">
                                            {{'freeSweeps' | languageTranslate}} {{'later' | languageTranslate}}:
                                        </span>
                                    </ng-container>
                                    {{'typeSelection' | languageTranslate: [{$innerVariable: 'manual'}]}}
                                    <ng-container *ngIf="showAdditionContent()">
                                        <span class="accent">
                                            {{'typeSelection' | languageTranslate: [{$innerVariable: 'manualAddition'}]}}
                                        </span>
                                    </ng-container>
                                </span>
                            </label>
                        </div>
                    </div>
                </ng-container>

                <div class="form-simple__item"
                     *ngIf="!generationIsFinished"
                     [ngStyle]="{
                        visibility: (step === pageSteps.settings) || (step === pageSteps.generation) ? 'visible' : 'hidden',
                        display: (step === pageSteps.settings) || (step === pageSteps.generation) ? 'block' : 'none'
                     }">
                        <textarea name=""
                                  id="keyWords"
                                  cols="30"
                                  rows="6"
                                  placeholder="Example: Scary looking clown"
                                  class="form-simple__input form-simple__textarea"></textarea>
                </div>
                <ng-container *ngIf="(step === pageSteps.settings) || ((step === pageSteps.generation) && !generationIsFinished)">
                    <div class="checkout-btns checkout-btns--row" *ngIf="step !== pageSteps.generation">
                        <button class="button"
                                (click)="setDefaultKeyWords()">
                            {{'typeItForMe' | languageTranslate}}
                        </button>
                    </div>
                    <div class="pic-generation__title" *ngIf="step === pageSteps.generation">
                        {{generatedImagesAmount}} of {{generateAllImagesAmount}}
                    </div>
                </ng-container>

                <ng-container *ngIf="generationIsFinished">
                    <div class="pic-generation__btns-row" style="flex-direction: column">
                        <div class="pic-generation__btns-text" style="color: #fff; margin-bottom: 1rem;">
                            {{'checkoutPageGenerationFinishedText' | languageTranslate: [{$innerVariable: 'main'}]}}
                            <ng-container *ngIf="showAdditionContent()">
                                <span class="accent">
                                    {{'checkoutPageGenerationFinishedText' | languageTranslate: [{$innerVariable: 'additional'}]}}
                                </span>
                            </ng-container>
                        </div>
                        <button class="button button-mint"
                                (click)="onBuyNew()">
                            {{'generateMore' | languageTranslate}}
                        </button>
                    </div>
                </ng-container>

                <div class="pic-generation" *ngIf="step === pageSteps.generation">
                    <div class="pic-generation__btns-row" *ngIf="!generationIsFinished">
                        <button class="button button-mint"
                                (click)="generateOne()">
                            {{'generate' | languageTranslate}} {{'one' | languageTranslate}}
                        </button>
                        <div class="pic-generation__btns-text">
                            {{'generateBtns' | languageTranslate: [{$innerVariable: 'one'}]}}
                            <ng-container *ngIf="showAdditionContent()">
                                <span class="accent">
                                    {{'generateBtns' | languageTranslate: [{$innerVariable: 'oneAddition'}]}}
                                </span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="pic-generation__btns-row pic-generation__btns-row--right" *ngIf="!generationIsFinished">
                        <button class="button button-mint"
                                (click)="generateAll()">
                            {{'generate' | languageTranslate}} {{'all' | languageTranslate}}
                        </button>
                        <div class="pic-generation__btns-text">
                            {{'generateBtns' | languageTranslate: [{$innerVariable: 'all'}]}}
                            <ng-container *ngIf="showAdditionContent()">
                                <span class="accent">
                                    {{'generateBtns' | languageTranslate: [{$innerVariable: 'allAddition'}]}}
                                </span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="pic-generation__gen-images">
                        <ul class="brushes brushes--no-padding">
                            <li class="brushes__item"
                                (click)="onShowLibraryBigImagePopup(item)"
                                *ngFor="let item of genBrushes">
                                    <img [src]="item.image_link ? item.image_link : item.image_full"
                                         alt=""
                                         class="brushes__img">
                            </li>
                        </ul>
                    </div>
                </div>

                <ng-container *ngIf="(step === pageSteps.terms) && checkOutForm.controls.terms.value">
                    <div class="gl-page-title gl-page-title--icon c-accent"
                         style="margin-bottom: 1rem; margin-top: 1rem"
                         *ngIf="viewIsCashAppHidden">
                        <svg>
                            <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                        </svg>
                        {{'cards' | languageTranslate}}
                    </div>

                    <div class="gl-page-title gl-page-title--icon c-accent"
                         style="margin-bottom: 1rem; margin-top: 1rem"
                         *ngIf="viewIsCardsHidden">
                        <svg>
                            <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                        </svg>
                        {{'paymentMethods' | languageTranslate: [{$innerVariable: 'cashApp'}]}}
                    </div>

                    <div class="checkout-tabs">
                        <div class="checkout-tabs__header" *ngIf="!viewIsCashAppHidden && !viewIsCardsHidden">
                            <div class="checkout-tabs__title gl-subtitle">
                                {{'select' | languageTranslate}} <br>  {{'paymentMethod' | languageTranslate}}
                            </div>
                            <div class="checkout-tabs__control button"
                                 *ngIf="!viewIsCardsHidden"
                                 [ngStyle]="{cursor: viewIsCashAppHidden ? 'auto' : 'pointer',
                                             pointerEvents: viewIsCashAppHidden ? 'none' : 'auto'}"
                                 [class.is-active]="!showCashApp"
                                 (click)="onShowCashApp(false)">
                                {{'cards' | languageTranslate}}
                            </div>
                            <div class="checkout-tabs__control button"
                                 *ngIf="!viewIsCashAppHidden"
                                 [ngStyle]="{cursor: viewIsCardsHidden ? 'auto' : 'pointer',
                                             pointerEvents: viewIsCardsHidden ? 'none' : 'auto'}"
                                 [class.is-active]="showCashApp || viewIsCardsHidden"
                                 (click)="onShowCashApp(true)">
                                {{'cashApp' | languageTranslate}}
                            </div>
                        </div>
                        <div class="checkout-tabs__items">
                            <div class="checkout-tabs__item"
                                 *ngIf="!viewIsCardsHidden"
                                 [class.is-active]="!showCashApp">
                                <div class="card-selection">
                                    <div class="saved">
<!--                                        <div class="saved__list">-->
<!--                                            <div *ngFor="let card of cards" class="form-simple__item card-selection__row">-->
<!--                                                <input formControlName="name"-->
<!--                                                       [id]="card.id"-->
<!--                                                       [type]="'radio'"-->
<!--                                                       class="form-simple__radio"-->
<!--                                                       [value]="card.id"-->
<!--                                                       name="email"-->
<!--                                                       (change)="changeMethod(card)"-->
<!--                                                       [checked]="activeMethod === card"-->
<!--                                                       autocomplete="off">-->
<!--                                                <label [for]="card.id">{{card.description}} - {{card.last_four}}</label>-->
<!--                                                <button class="card-selection__remove"-->
<!--                                                        [ngStyle]="{visibility: card.verify !== 1 ? 'visible' : 'hidden',-->
<!--                                                                pointerEvents: card.verify !== 1 ? 'auto' : 'none'}"-->
<!--                                                        (click)="showVerifyPopup(card)"-->
<!--                                                        title="{{'verify' | languageTranslate}}">-->
<!--                                                    {{'verify' | languageTranslate}}-->
<!--                                                </button>-->
<!--                                                <button class="card-selection__delete"-->
<!--                                                        (click)="onDeleteCard(card)"-->
<!--                                                        title="{{'delete' | languageTranslate}}">-->
<!--                                                    {{'delete' | languageTranslate}}-->
<!--                                                </button>-->
<!--                                            </div>-->

<!--                                        </div>-->
                                        <app-cards-selection [cards]="cards"
                                                             (showVerifyPopup)="showVerifyPopup($event)"
                                                             (closeVerifyPopup)="onCloseVerifyPopup($event)"
                                                             (changeMethod)="changeMethod($event)"></app-cards-selection>
                                    </div>

                                    <div class="checkout-btns">
                                        <button class="button"
                                                (click)="onSubmit()"
                                                [disabled]="!((!showCashApp && cards.length))">
                                            {{'charge' | languageTranslate}}
                                        </button>
                                        <button class="button"
                                                style="margin-bottom: 1rem"
                                                (click)="showDebitCardPopup()">
                                            {{'addNewCard' | languageTranslate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="checkout-tabs__item checkout-tabs__item--cash-app"
                                 *ngIf="!viewIsCashAppHidden"
                                 [class.is-active]="showCashApp || viewIsCardsHidden">
                                <div class="checkout-btns">
                                    <button class="button"
                                            style="margin-bottom: 1rem"
                                            (click)="onSubmit()">
                                        {{'charge' | languageTranslate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </form>

            <div class="checkout-btns checkout-btns--row" *ngIf="!showMint && (step !== pageSteps.generation)">
                <button class="button"
                        (click)="goBack()"
                        [ngStyle]="{visibility: step === 1 ? 'hidden' : 'visible'}">
                    {{'btnBack' | languageTranslate}}
                </button>
                <button class="button"
                        (click)="goNext()"
                        *ngIf="!isUnfinished && (step !== pageSteps.terms)">
                    {{'continue' | languageTranslate}}
                </button>
                <button class="button button-unfinished"
                        style="width: 10rem; min-width: 10rem"
                        (click)="showUnfinishedWork()"
                        *ngIf="isUnfinished">
                    {{'unfinishedWork' | languageTranslate}}
                </button>
            </div>

            <ng-container *ngIf="step === pageSteps.spinner">
                <app-spinner [data]="spinnerData"
                             (onShowDebitCardPopup)="showDebitCardPopup()"
                             (onSpinResult)="onSpinResult($event)"></app-spinner>
            </ng-container>
        </div>
    </div>
</div>

<app-popup-debit-card [popupInfo]="popupDebitCardInfo"
                      [statesList]="states"
                      (close)="closeDebitCardPopup($event)">
</app-popup-debit-card>

<!--<app-investments-nft-popup  *ngIf="showNFTPopup && NFTPopupData"-->
<!--                            [popupData]="NFTPopupData.data"-->
<!--                            [popupType]="NFTPopupData.type"-->
<!--                            (emitDepositConfirm)="onDepositConfirm($event)"-->
<!--                            (close)="onPopupNFTClose()"></app-investments-nft-popup>-->

<div [ngStyle]="{display: showNFTPopup ? 'block' : 'none'}">
    <app-checkout-charge-popup *ngIf="NFTPopupData"
                               [show]="showNFTPopup"
                               [popupData]="NFTPopupData.data"
                               (emitDepositConfirm)="onDepositConfirm($event)"
                               (close)="onPopupNFTClose()">
    </app-checkout-charge-popup>
</div>

<app-iframe-popup *ngIf="iframePopup.show"
                  [url]="iframePopup.url"
                  (onClose)="hideIframePopup($event)"></app-iframe-popup>

<app-popup-verify-card [popupInfo]="verifyPopup"
                       (onClose)="onCloseVerifyPopup($event)"></app-popup-verify-card>

<app-popup-wrapper [show]="isTermsExpandedShown" (onClose)="closeTermsExpanded($event)">
    <div class="investments-popup__scrolled" id="scrolled-wrapper">
        <div class="nft-terms" [innerHTML]="terms"></div>
    </div>
</app-popup-wrapper>

<app-popup-big-image  *ngIf="showLibraryBigImagePopup && libraryBigImagePopupData"
                      [popupData]="libraryBigImagePopupData.data"
                      (close)="onPopupLibraryBigImageClose()"></app-popup-big-image>
