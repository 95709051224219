<div class="login__forms" [class.is-blurred]="gamePopupInfo.show || popupShop.show || popupDebitCardInfo.show">
    <div class="login__tab">
        <ng-container *ngIf="showAdditionalContent()">
            <app-balance-heading></app-balance-heading>
        </ng-container>

        <div class="page-block page-block--no-mt" *ngIf="!showAdditionalContent()">
            <div class="gl-page-title gl-page-title--icon c-accent">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
                {{'limitedOffer' | languageTranslate}}
            </div>

            <div class="gl-subtitle gl-subtitle--opacity">
                {{'shopLimitedOfferBlockDescription' | languageTranslate}}
            </div>
            <div class="shop-btns shop-btns--no-btn-mb">
                <a class="button"
                   type="button"
                   [routerLink]="RouterPaths.checkout">
                    {{'purchase' | languageTranslate}}
                </a>
            </div>
        </div>

        <div class="page-block" *ngIf="showAdditionalContent() && showWheel">
            <div class="gl-page-title gl-page-title--icon c-accent">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
                {{'shopSpinnerBlockTitle' | languageTranslate}}
            </div>

            <div class="gl-subtitle gl-subtitle--opacity">
                {{'shopSpinnerBlockText' | languageTranslate}}
            </div>
            <app-spinner [data]="spinnerData"
                         (onShowDebitCardPopup)="onShowDebitCardPopup()"
                         (onSpinResult)="onSpinResult($event)"></app-spinner>
        </div>

        <div class="page-block" *ngIf="showAdditionalContent()">
            <div class="gl-page-title gl-page-title--icon c-accent">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
                {{'limitedSweepsOffer' | languageTranslate}}
            </div>
            <div class="gl-subtitle gl-subtitle--opacity">
                {{'limitedSweepsOfferText' | languageTranslate}}
            </div>
            <form class="login__form">

                <div class="form-simple__item">
<!--                    <mat-form-field>-->
<!--                        <mat-select #methodsSelect-->
<!--                                    formControlName="method"-->
<!--                                    (valueChange)="onProviderChange($event)"-->
<!--                                    [(value)]="selectedProvider" panelClass="my-select-panel-border-yellow">-->
<!--                            <mat-select-trigger>-->
<!--                                {{methodsSelect?.value?.name}}-->
<!--                            </mat-select-trigger>-->
<!--                            <mat-option *ngFor="let method of providers" [value]="method">-->
<!--                                {{method.name}}-->
<!--                            </mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
                    <ul class="brushes brushes--named">
                        <li class="brushes__item" *ngFor="let item of providers"
                            (click)="onChangeSelectedProvider(item)"
                            [class.is-active]="selectedProviderId === item.id">
                            <div class="brushes__item-name">{{item.name}}</div>
                            <img [src]="item.image_link"
                                 alt=""
                                 class="brushes__img">
                        </li>
                    </ul>
                    <div class="form-simple__addition-info" style="text-align: center">
                        {{'selectProviderFromListAbove' | languageTranslate}}
                    </div>
                    <div class="balance-heading">
                        <div class="balance-heading__col">
                            <div class="balance-heading__row">
                                <div class="gl-text">{{'availableEntries' | languageTranslate}}:</div>
                                <!--            <div class="gl-text">{{entries | currency : activeCurrency : 'symbol-narrow'}}</div>-->
                                <div class="gl-text">{{formatCurrency(entriesVal)}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="shop-btns shop-btns--no-btn-mb" id="gamesBtns">
                    <button class="snake-btn is-notouched button button-mint"
                            id="playBtn"
                            (click)="onShowGamePopup()"
                            type="button">
                        {{'play' | languageTranslate}}
                        <svg><rect></rect></svg>
                    </button>
                    <a class="button"
                            *ngIf="showBuyCrypto"
                            type="button"
                            [routerLink]="RouterPaths.checkout">
                        {{'purchase' | languageTranslate}}
                    </a>
                </div>

<!--                <div class="shop-btns shop-btns&#45;&#45;no-btn-mb" style="justify-content: center">-->
<!--                    <a class="button button-mint"-->
<!--                            type="button"-->
<!--                            style="min-width: 10rem; width: 10rem"-->
<!--                            target="_blank"-->
<!--                            [routerLink]="RouterPaths.gamesPlayground+ '/'+ selectedProvider.short_name">-->
<!--                        go to playground-->
<!--                    </a>-->
<!--                </div>-->
            </form>
        </div>

        <!--<div class="page-block" *ngIf="showBuyCrypto">-->
            <!--<div class="gl-page-title gl-page-title&#45;&#45;icon c-accent">-->
                <!--<svg>-->
                    <!--<use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>-->
                <!--</svg>-->
                <!--{{'buyCryptoGiftCards' | languageTranslate}}-->
            <!--</div>-->
            <!--<div class="gl-subtitle gl-subtitle&#45;&#45;opacity" [innerHTML]="buyCryptoDescription"></div>-->

            <!--<div class="btns-list">-->
                <!--<div class="btns-list__row" *ngFor="let item of buyCryptoList">-->
                    <!--<button class="button"-->
                            <!--(click)="goToCheckout(item.short_name)"-->
                            <!--type="button">-->
                        <!--{{item.name}}-->
                    <!--</button>-->
                    <!--<div class="btns-list__descr" [innerHTML]="item.description"></div>-->
                <!--</div>-->
<!--&lt;!&ndash;                <div class="btns-list__row">&ndash;&gt;-->
<!--&lt;!&ndash;                    <button class="button"&ndash;&gt;-->
<!--&lt;!&ndash;                            (click)="onActivateBuy()"&ndash;&gt;-->
<!--&lt;!&ndash;                            type="button">&ndash;&gt;-->
<!--&lt;!&ndash;                        {{'activate' | languageTranslate}}&ndash;&gt;-->
<!--&lt;!&ndash;                    </button>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="btns-list__descr"><span>{{'activateCryptoGiftCardText' | languageTranslate}}</span></div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
            <!--</div>-->
        <!--</div>-->

<!--        <div class="page-block">-->
<!--            <div class="gl-page-title gl-page-title&#45;&#45;icon c-accent">-->
<!--                <svg>-->
<!--                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>-->
<!--                </svg>-->
<!--                {{'sellCrypto' | languageTranslate}}-->
<!--            </div>-->
<!--            <div class="gl-subtitle gl-subtitle&#45;&#45;opacity" [innerHTML]="sellCryptoDescription"></div>-->
<!--            <div class="btns-list">-->
<!--                <div class="btns-list__row">-->
<!--                    <button class="button"-->
<!--                            (click)="onSellCrypto()"-->
<!--                            type="button">-->
<!--                        {{'continue' | languageTranslate}}-->
<!--                    </button>-->
<!--                    <div class="btns-list__descr">-->
<!--                        <span>{{sellCryptoPercent}}% </span>-->
<!--                        {{'sellCryptoInfo' | languageTranslate: [{percent: sellCryptoPercent}]}}-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="page-block" *ngIf="!showAdditionalContent()">
            <div class="gl-page-title gl-page-title--icon c-accent">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
                {{'sellGiftCards' | languageTranslate}}
            </div>
            <div class="gl-subtitle gl-subtitle--opacity" [innerHTML]="sellGiftsDescription"></div>
            <form  [formGroup]="searchCatalogForm" class="login__form">

                <div class="shop-btns shop-btns--no-btn-mb">
                    <div class="form-simple__item" style="width: 100%; margin-bottom: 0">
                        <input formControlName="search"
                               placeholder="{{'searchCatalog' | languageTranslate}}"
                               [type]="'text'"
                               (input)="onGiftCardSearch($event)"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>
                </div>
                <div class="btns-list btns-list--max-height">
                    <div class="btns-list__row" *ngFor="let item of sellGiftCardsListSearch">
                        <button class="button"
                                (click)="onSellGiftCards(item.id, item.name)"
                                type="button">
                            {{item.name}}
                        </button>
                        <div class="btns-list__descr" [innerHTML]="item.description"></div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<app-popup-rewards-game-form [popupInfo]="gamePopupInfo"
                             (updateBalance)="onUpdateBalance()"
                             (onGameSweep)="onGameSweep()"
                             (close)="onCloseGamePopup()"></app-popup-rewards-game-form>

<app-popup-shop (close)="onPopupShopClose()"
                (send)="onSendPopupForm($event)"
                [popupInfo]="popupShop"></app-popup-shop>

<app-popup-debit-card [popupInfo]="popupDebitCardInfo"
                      [statesList]="popupDebitCardInfo.states"
                      (close)="closeDebitCardPopup($event)">
</app-popup-debit-card>
