import * as tslib_1 from "tslib";
import { PRIZES_CONFIG, WITHDRAW_CHECK_CONFIG } from "../config/api";
import { RequestService } from "./request.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var CardsService = /** @class */ (function (_super) {
    tslib_1.__extends(CardsService, _super);
    function CardsService(http, loaderService) {
        var _this = _super.call(this, http, loaderService) || this;
        _this.config = PRIZES_CONFIG;
        _this.configCheck = WITHDRAW_CHECK_CONFIG;
        return _this;
    }
    CardsService.prototype.addDebitCard = function (card, month, year, cvv, name, firstName, lastName, billingAddress, billingZip, city, stateId) {
        return this.request(this.config.addDebitCard, { card: card, month: month, year: year, cvv: cvv, name: name, firstName: firstName, lastName: lastName, billingAddress: billingAddress, billingZip: billingZip, city: city, stateId: stateId });
    };
    CardsService.prototype.addAdvancedDebitCard = function (card, month, year, cvv, name, firstName, lastName, billingAddress, billingZip, city, stateId) {
        return this.request(this.config.addAdvancedDebitCard, { card: card, month: month, year: year, cvv: cvv, name: name, firstName: firstName, lastName: lastName, billingAddress: billingAddress, billingZip: billingZip, city: city, stateId: stateId });
    };
    CardsService.prototype.addBankAccount = function (routingNumber, accountNumber) {
        return this.request(this.config.addBankAccount, { routingNumber: routingNumber, accountNumber: accountNumber });
    };
    CardsService.prototype.addCashAppAccount = function (routingNumber, accountNumber) {
        return this.request(this.config.addCashAppAccount, { routingNumber: routingNumber, accountNumber: accountNumber });
    };
    CardsService.prototype.removeDebitCard = function (id) {
        return this.request(this.config.removeDebitCard, { id: id });
    };
    CardsService.prototype.getDebitCardInfo = function (needLoader) {
        if (needLoader === void 0) { needLoader = true; }
        return this.request(this.config.getDebitCardInfo, {}, needLoader);
    };
    CardsService.prototype.getBankAccountInfo = function (needLoader) {
        if (needLoader === void 0) { needLoader = true; }
        return this.request(this.config.getBankAccountInfo, {}, needLoader);
    };
    CardsService.prototype.getCashAppInfo = function (needLoader) {
        if (needLoader === void 0) { needLoader = true; }
        return this.request(this.config.getCashAppInfo, {}, needLoader);
    };
    CardsService.prototype.getDebitCardAdditionInfo = function () {
        return this.request(this.config.getDebitCardAdditionInfo, {});
    };
    CardsService.prototype.addDebitCardAdditionInfo = function (firstName, lastName, address, address2, city, zip, stateId, dob, lastFour, shortName) {
        return this.request(this.config.addDebitCardAdditionInfo, { firstName: firstName, lastName: lastName, address: address, address2: address2, city: city, zip: zip, stateId: stateId, dob: dob, lastFour: lastFour, shortName: shortName });
    };
    CardsService.prototype.getGiftCardInfo = function (type) {
        return this.request(this.config.getGiftCardInfo, { type: type });
    };
    CardsService.prototype.redeemViaVisaGiftCard = function (amount, qty) {
        return this.request(this.config.redeemViaVisaGiftCard, { amount: amount, qty: qty });
    };
    CardsService.prototype.redeemViaAmazonGiftCard = function (amount, qty) {
        return this.request(this.config.redeemViaAmazonGiftCard, { amount: amount, qty: qty });
    };
    CardsService.prototype.redeemViaDebitCard = function (amount) {
        return this.request(this.config.redeemViaDebitCard, { amount: amount });
    };
    CardsService.prototype.redeemViaBankAccount = function (amount) {
        return this.request(this.config.redeemViaBankAccount, { amount: amount });
    };
    CardsService.prototype.redeemViaCashApp = function (amount) {
        return this.request(this.config.redeemViaCashApp, { amount: amount });
    };
    CardsService.prototype.withdraw = function (shortname, amount) {
        return this.request(this.config.withdraw, { shortname: shortname, amount: amount });
    };
    CardsService.prototype.getPaypalInfo = function () {
        return this.request(this.config.getPaypalInfo, {});
    };
    CardsService.prototype.verifyPaypal = function (email, retype) {
        return this.request(this.config.verifyPaypal, { email: email, retype: retype });
    };
    CardsService.prototype.redeemViaVenmoPaypal = function (email, amount, method) {
        if (method) {
            return this.request(this.config.redeemViaVenmoPaypal, { email: email, amount: amount, method: method });
        }
        else {
            return this.request(this.config.redeemViaVenmoPaypal, { email: email, amount: amount });
        }
    };
    CardsService.prototype.getVenmoInfo = function () {
        return this.request(this.config.getVenmoInfo, {});
    };
    CardsService.prototype.redeemViaVenmoTag = function (amount, handle, method) {
        if (method) {
            return this.request(this.config.redeemViaVenmoTag, { amount: amount, handle: handle, method: method });
        }
        else {
            return this.request(this.config.redeemViaVenmoTag, { amount: amount, handle: handle });
        }
    };
    CardsService.prototype.verifyVenmo = function (address, retype) {
        return this.request(this.config.verifyVenmo, { address: address, retype: retype });
    };
    CardsService.prototype.getDebitCards = function () {
        return this.request(this.config.getDebitCards, {});
    };
    CardsService.prototype.getBankAccounts = function () {
        return this.request(this.config.getBankAccounts, {});
    };
    CardsService.prototype.getCashAppAccounts = function () {
        return this.request(this.config.getCashAppAccounts, {});
    };
    CardsService.prototype.changeActiveCard = function (uuid) {
        return this.request(this.config.changeActiveCard, { uuid: uuid });
    };
    CardsService.prototype.changeActiveBankAccount = function (uuid) {
        return this.request(this.config.changeActiveBankAccount, { uuid: uuid });
    };
    CardsService.prototype.changeActiveCashAppAccount = function (uuid) {
        return this.request(this.config.changeActiveCashAppAccount, { uuid: uuid });
    };
    CardsService.prototype.removeAdvancedDebitCard = function (uuid) {
        return this.request(this.config.removeAdvancedDebitCard, { uuid: uuid });
    };
    CardsService.prototype.removeBankAccount = function (uuid) {
        return this.request(this.config.removeBankAccount, { uuid: uuid });
    };
    CardsService.prototype.removeCashAppAccount = function (uuid) {
        return this.request(this.config.removeCashAppAccount, { uuid: uuid });
    };
    CardsService.prototype.getPaynoteInfo = function () {
        return this.request(this.config.getPaynoteInfo, {});
    };
    CardsService.prototype.redeemViaPaynote = function (amount, handle) {
        return this.request(this.config.redeemViaPaynote, { amount: amount, handle: handle });
    };
    CardsService.prototype.verifyCard = function (amount, id) {
        return this.request(this.config.verifyCard, { amount: amount, id: id });
    };
    CardsService.prototype.getCheckAddress = function (amount) {
        return this.request(this.configCheck.getAddress, { amount: amount });
    };
    CardsService.prototype.getCheckTotal = function (amount) {
        return this.request(this.configCheck.getTotal, { amount: amount });
    };
    CardsService.prototype.redeemViaCheck = function (amount, address) {
        return this.request(this.configCheck.onConfirm, { amount: amount, address: address });
    };
    CardsService.prototype.getRoutingList = function (shortName, value) {
        if (value) {
            return this.request(this.config.getRoutingList, { number: value }, false);
        }
        else {
            return this.request(this.config.getRoutingList, { method: shortName });
        }
    };
    CardsService.prototype.getSavedInstitutions = function (method) {
        return this.request(this.config.getSavedInstitutions, { method: method });
    };
    CardsService.prototype.sendLegalData = function (firstName, lastName) {
        return this.request(this.config.sendLegalData, { firstName: firstName, lastName: lastName });
    };
    CardsService.prototype.redeemViaPaynoteDirect = function (amount, routingNumberId, accountNumber, method) {
        if (method) {
            return this.request(this.config.redeemViaPaynoteDirect, { amount: amount, routingNumberId: routingNumberId, accountNumber: accountNumber, method: method });
        }
        else {
            return this.request(this.config.redeemViaPaynoteDirect, { amount: amount, routingNumberId: routingNumberId, accountNumber: accountNumber });
        }
    };
    CardsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CardsService_Factory() { return new CardsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: CardsService, providedIn: "root" });
    return CardsService;
}(RequestService));
export { CardsService };
