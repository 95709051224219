import { OnInit, QueryList, ElementRef } from '@angular/core';
import { Validators } from "@angular/forms";
import { RouterPaths } from "../../config/router-paths.model";
import { ERROR_CODE } from "../../config/error_code";
import { TIMERS_CONFIG } from "../../config/project";
import { AUTH_CODES } from "../../config/auth_codes";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../service/app-version/appVersion.service";
import * as i3 from "../../modules/auth/_services/authentication.service";
import * as i4 from "../../service/login.service";
import * as i5 from "../../service/loader.service";
import * as i6 from "../../service/phoneMask.service";
import * as i7 from "@angular/router";
import * as i8 from "../../modules/language/language.service";
import * as i9 from "../../service/content-displaying.service";
import * as i10 from "../../service/app/appPrompt.service";
import * as i11 from "../../service/partner.service";
import * as i12 from "../../shared/popup/popup.component";
var AUTH_TYPE = {
    sms: 'smsPin',
    smsPinPassword: 'smsPinPassword',
    authPin: 'authPin',
    authPinPassword: 'authPinPassword',
    password: 'clintPassword'
};
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, fb, appVersionService, authenticationService, loginService, loaderService, phoneMaskService, router, activatedRoute, auth, languageService, contentDisplayingService, appPromptService, partnerService, popupComponent) {
        this.formBuilder = formBuilder;
        this.fb = fb;
        this.appVersionService = appVersionService;
        this.authenticationService = authenticationService;
        this.loginService = loginService;
        this.loaderService = loaderService;
        this.phoneMaskService = phoneMaskService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.auth = auth;
        this.languageService = languageService;
        this.contentDisplayingService = contentDisplayingService;
        this.appPromptService = appPromptService;
        this.partnerService = partnerService;
        this.popupComponent = popupComponent;
        this.phoneFormActive = true;
        this.authCode = null;
        this.registrationPartActive = false;
        this.isRegistrationUpdate = false;
        this.phoneLastFour = ' ';
        this.numOfDigits = 4;
        this.pointId = null;
        this.riverPayAccountId = '';
        this.terminalConnectingPopup = false;
        this.showGamesSelection = false;
        this.menu = false;
        this.RouterPaths = RouterPaths;
        this.contactUsPopup = { show: false };
        this.loginType = 'email';
        this.authType = '';
        this.IOSInstallBtn = false;
        this.androidInstallBtn = false;
        // Detects if device is in standalone mode
        this.isInStandaloneMode = function () { return ('standalone' in window.navigator) && (window.navigator['standalone']); };
        this.auth.logout();
        this.phoneFormInit();
        this.pinFormInit();
        this.popupComponent.closePopup();
        document.body.classList.remove('hide-menu');
        this.deferredPrompt = this.appPromptService.deferredPrompt;
    }
    LoginComponent.prototype.onbeforeinstallprompt = function (e) {
        e.preventDefault();
        // store install avaliable event
        this.deferredPrompt = e;
        // show button with display:block;
        this.androidInstallBtn = true;
        this.appPromptService.deferredPrompt = this.deferredPrompt;
    };
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.phoneMask = this.phoneMaskService.setPhoneMask();
        this.setSavedUserInfo();
        this.activatedRoute.queryParams.subscribe(function (params) {
            if (params.phone) {
                _this.changeLoginType('phone');
                _this.phoneForm.controls.phone.setValue(params.phone);
            }
            if (params.email) {
                _this.changeLoginType('email');
                _this.phoneForm.controls.email.setValue(params.email);
            }
            if (params.email || params.phone) {
                _this.onPhoneSubmit();
            }
            else {
                _this.loaderService.hide();
            }
        });
    };
    LoginComponent.prototype.phoneFormInit = function () {
        this.phoneForm = this.formBuilder.group({
            phone: [null, Validators.required],
            email: [null, Validators.required],
            remember_user: [false],
        });
    };
    LoginComponent.prototype.pinFormInit = function () {
        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([]),
            password: [null, Validators.required],
        });
        for (var i = 0; i < this.numOfDigits; i++) {
            this.pinForm.get('digits').push(this.formBuilder.control(null, Validators.required));
        }
    };
    LoginComponent.prototype.setSavedUserInfo = function () {
        if (localStorage.remember_user && (localStorage.remember_user !== "false")) {
            this.phoneForm.controls.remember_user.setValue(true);
            if (localStorage.phone && (localStorage.phone !== 'null')) {
                this.phoneForm.controls.phone.setValue(localStorage.phone);
                this.phoneForm.controls.email.setValue(null);
            }
            if (localStorage.email && (localStorage.email !== 'null')) {
                this.loginType = 'email';
                this.phoneForm.controls.email.setValue(localStorage.email);
                this.phoneForm.controls.phone.setValue(null);
            }
        }
        else {
            this.phoneForm.controls.remember_user.setValue(false);
            this.phoneForm.controls.phone.setValue(null);
            this.phoneForm.controls.email.setValue(null);
        }
        this.focusFirstInput();
    };
    LoginComponent.prototype.resendSms = function () {
        this.onPhoneSubmit();
    };
    LoginComponent.prototype.onPhoneSubmit = function () {
        var _this = this;
        if ((this.isLoginTypePhone() && this.phoneForm.controls.phone.invalid) ||
            (this.isLoginTypeEmail() && this.phoneForm.controls.email.invalid)) {
            return;
        }
        this.riverPayAccountId = '';
        var phone = this.isLoginTypePhone() ? this.phoneForm.controls.phone.value : null;
        var email = this.isLoginTypeEmail() ? this.phoneForm.controls.email.value : null;
        var authHash = localStorage.getItem('authHash');
        // let country = this.phoneForm.controls.country.value;
        this.authenticationService.checkPhoneAndSendPin(phone, email, authHash).subscribe(function (data) {
            // if (environment.frontEndTest) {
            //     data.status = false;
            //     data.code = 414;
            //     data.firstName = "";
            //     data.email = "dkdk@kddk";
            // }
            try {
                if (!data.status) {
                    if (data.code) {
                        if (data.code == AUTH_CODES.registerNewUser) {
                            _this.errorHandlerRegisterNewUser();
                        }
                        else if (data.code == AUTH_CODES.showSMSPin) {
                            _this.showAuthSecondStep(data.code);
                            _this.showSMSLoginStep();
                        }
                        else if (data.code == AUTH_CODES.showAuthPin) {
                            _this.showAuthSecondStep(data.code);
                            _this.showAuthLoginStep();
                        }
                        else if (data.code == AUTH_CODES.showPassword) {
                            _this.showAuthSecondStep(data.code);
                            _this.showPasswordLoginStep();
                        }
                        else if (data.code == AUTH_CODES.updateUserInfo) {
                            _this.errorHandlerUpdateUserInfo(data);
                        }
                        else {
                            _this.popupComponent.showPopupError({ text: data.message });
                        }
                    }
                    else {
                        _this.popupComponent.showPopupError({ text: data.message });
                    }
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            setTimeout(function () {
                _this.loaderService.hide();
            }, 0);
        });
    };
    LoginComponent.prototype.showAuthSecondStep = function (code) {
        this.rememberUser();
        this.authCode = code;
        this.phoneFormActive = false;
    };
    LoginComponent.prototype.showSMSLoginStep = function () {
        var phone = this.isLoginTypePhone() ? this.phoneForm.controls.phone.value : null;
        this.phoneLastFour = phone.substr(-4);
        this.authType = AUTH_TYPE.sms;
        this.focusPinInputs();
    };
    LoginComponent.prototype.showAuthLoginStep = function () {
        this.authType = AUTH_TYPE.authPin;
        this.focusPinInputs();
    };
    LoginComponent.prototype.showPasswordLoginStep = function () {
        this.authType = AUTH_TYPE.password;
        this.focusFirstInput();
    };
    LoginComponent.prototype.rememberUser = function () {
        var phone = this.isLoginTypePhone() ? this.phoneForm.controls.phone.value : null;
        var email = this.isLoginTypeEmail() ? this.phoneForm.controls.email.value : null;
        if (this.phoneForm.controls.remember_user.value) {
            localStorage.phone = phone;
            localStorage.email = email;
            localStorage.remember_user = this.phoneForm.controls.remember_user.value;
        }
        else {
            localStorage.phone = '';
            localStorage.email = '';
            localStorage.remember_user = "false";
        }
    };
    LoginComponent.prototype.errorHandlerRegisterNewUser = function () {
        this.registrationPartActive = true;
        this.phoneFormActive = false;
        this.isRegistrationUpdate = false;
        this.userRegistrationUpdateData = null;
    };
    LoginComponent.prototype.errorHandlerUpdateUserInfo = function (data) {
        this.isRegistrationUpdate = true;
        this.registrationPartActive = true;
        this.phoneFormActive = false;
        this.userRegistrationUpdateData = data;
    };
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        var phone = this.isLoginTypePhone() ? this.phoneForm.controls.phone.value : null;
        var email = this.isLoginTypeEmail() ? this.phoneForm.controls.email.value : null;
        var password = this.isSecondStepPassword() || this.isSecondStepAuthAuthPinPassword() || this.isSecondStepAuthSMSPinPassword() ? this.pinForm.controls.password.value : null;
        var code = this.authCode;
        var authHash = localStorage.getItem('authHash');
        var pin = this.pinForm.controls.digits.value;
        pin = this.isSecondStepAuthSMSPin() || this.isSecondStepAuthAuthPin() ? pin.join('') : null;
        this.authenticationService.login(email, phone, pin, password, code, authHash).subscribe(function (data) {
            _this.checkLoginSubmit(data);
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    LoginComponent.prototype.checkLoginSubmit = function (data) {
        var _this = this;
        try {
            if (data.status) {
                // this.showRiverPayAccounts(data);
                // this.showContactsFormFirst = data.result.first_name == '' && data.result.last_name == '' && data.result.email == '';
                // this.showContactsFormFirst = true;
                this.redirectToRewards();
            }
            else {
                if (data.code) {
                    if (data.code == ERROR_CODE.blocked || data.code == ERROR_CODE.authOver) {
                        this.popupComponent.showPopupErrorReturnEvent({ text: data.message, closePopupAfter: TIMERS_CONFIG.popupHideAfter }, function () {
                            _this.logout();
                        });
                    }
                    else {
                        this.popupComponent.showPopupError({ text: data.message });
                    }
                }
                else {
                    this.popupComponent.showPopupError({ text: data.message });
                }
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    LoginComponent.prototype.redirectToRewards = function () {
        // if (this.showContactsFormFirst) {
        //     this.router.navigate(['./home/contacts']);
        // } else {
        //     this.router.navigate([RouterPaths.shop]);
        // }
        if (this.showAdditionContent()) {
            this.router.navigate([RouterPaths.shop]);
        }
        else {
            this.router.navigate([RouterPaths.checkout]);
        }
    };
    LoginComponent.prototype.showAdditionContent = function () {
        return this.contentDisplayingService.showAdditionContent();
    };
    LoginComponent.prototype.isPhoneClassActive = function () {
        if (this.phoneFormActive) {
            return true;
        }
        return false;
    };
    LoginComponent.prototype.isRegistrationClassActive = function () {
        if (this.registrationPartActive) {
            return true;
        }
        return false;
    };
    LoginComponent.prototype.isPinClassActive = function () {
        if (!this.phoneFormActive) {
            return true;
        }
        return false;
    };
    LoginComponent.prototype.check = function (index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        }
        else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null);
                this.inputs.toArray()[index - 1].nativeElement.focus();
            }
            else {
                console.log('first field');
            }
        }
    };
    LoginComponent.prototype.onRememberUser = function (e) {
        var checked = e.target.checked;
        this.phoneForm.controls.remember_user.setValue(!!checked);
    };
    LoginComponent.prototype.onRegisterNewUser = function (data) {
        var _this = this;
        var phone = this.isLoginTypePhone() ? this.phoneForm.controls.phone.value : null;
        var firstName = data.firstName;
        var lastName = data.lastName;
        var email = data.email;
        var promo = data.promo;
        var password = data.password;
        var pin = data.pin;
        if (pin.length !== 4) {
            var errorMessage = this.languageService.getString('messagesData', 'errorIncorrectPin');
            this.popupComponent.showPopupError({ text: errorMessage });
            return;
        }
        if (!firstName || !lastName || !email || !password || !pin) {
            var errorMessage = this.languageService.getString('messagesData', 'notAllFieldsAreFilled');
            this.popupComponent.showPopupError({ text: errorMessage });
            return;
        }
        this.loaderService.show();
        if (!this.isRegistrationUpdate) {
            this.authenticationService.register(firstName, lastName, email, promo, password, phone, pin).subscribe(function (data) {
                _this.loaderService.hide();
                try {
                    if (data.status) {
                        var riverPayAccounts = data.accounts;
                        if (_this.phoneForm.controls.remember_user.value) {
                            localStorage.phone = phone;
                            localStorage.email = email;
                        }
                        else {
                            localStorage.phone = '';
                            localStorage.email = '';
                        }
                        localStorage.remember_user = _this.phoneForm.controls.remember_user.value;
                        _this.authenticationService.setData(data.result);
                        if (riverPayAccounts.length) {
                            _this.checkLoginSubmit(data);
                        }
                        else {
                            _this.popupComponent.showPopupError();
                        }
                    }
                    else {
                        _this.popupComponent.showPopupError({ text: data.message });
                    }
                }
                catch (e) {
                    _this.loaderService.hide();
                    _this.popupComponent.showPopupError();
                }
            }, function (error) {
                _this.loaderService.hide();
                _this.popupComponent.showPopupError();
            });
        }
        else {
            this.authenticationService.addPassword(phone, firstName, lastName, email, password, pin).subscribe(function (data) {
                _this.loaderService.hide();
                try {
                    if (data.status) {
                        if (_this.phoneForm.controls.remember_user.value) {
                            localStorage.phone = phone;
                            localStorage.email = email;
                        }
                        else {
                            localStorage.phone = '';
                            localStorage.email = '';
                        }
                        _this.checkLoginSubmit(data);
                    }
                    else {
                        _this.popupComponent.showPopupError({ text: data.message });
                    }
                }
                catch (e) {
                    _this.loaderService.hide();
                    _this.popupComponent.showPopupError();
                }
            }, function (error) {
                _this.loaderService.hide();
                _this.popupComponent.showPopupError();
            });
        }
    };
    LoginComponent.prototype.setPhoneMask = function (country) {
        this.phoneMask = this.phoneMaskService.setPhoneMask();
    };
    LoginComponent.prototype.onCountryChange = function (e) {
        var value = e.target.value;
        this.setPhoneMask(value);
    };
    // getPartner() {
    //     this.currentPartner = this.PARTNERS[this.partnerService.currentPartner];
    // }
    LoginComponent.prototype.getPartnerName = function () {
        // return '';
        return this.partnerService.getBusinessName() ? this.partnerService.getBusinessName() : 'Senet';
        // if (this.isApp) {
        //     return this.terminalService.getTitle();
        // } else {
        //     return this.partnerService.getName();
        // }
        //return this.currentPartner.name;
    };
    LoginComponent.prototype.getPartnerLogo = function () {
        return this.partnerService.getPartnerLogo();
        // return this.partnerService.getPartnerLogo() ? this.partnerService.getPartnerLogo() : '../assets/images/logo.png';
    };
    LoginComponent.prototype.isIos = function () {
        var userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };
    LoginComponent.prototype.install = function () {
        if (this.isIos() && !this.isInStandaloneMode()) {
            var userAgent = window.navigator.userAgent.toLowerCase();
            this.IOSInstallBtn = true;
            var device = this.languageService.getString('installData', 'device');
            if (/iphone/.test(userAgent)) {
                device = 'IPhone';
            }
            else if (/ipad/.test(userAgent)) {
                device = 'IPad';
            }
            // this.ios_popup_text = `Install this webapp on your ${device}: `;
            this.ios_popup_text = this.languageService.getStringWithVariables([{ 'device': device }], 'installData', 'installThisWebapp');
        }
        else {
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then(function (choiceResult) {
                if (choiceResult.outcome === 'accepted') {
                    // user accept the prompt
                    // lets hidden button
                    //this.androidInstallBtn = false;
                    //this.appInstalled = true;
                    //document.location.reload();
                    //this.appInstalled = true;
                }
                else {
                    console.log('User dismissed the prompt');
                }
                //this.deferredPrompt = null;
            });
        }
    };
    LoginComponent.prototype.focusPinInputs = function () {
        var _this = this;
        setTimeout(function () {
            _this.inputs.toArray()[0].nativeElement.focus();
        }, 0);
    };
    LoginComponent.prototype.focusFirstInput = function () {
        setTimeout(function () {
            document.querySelector('input').focus();
        });
    };
    LoginComponent.prototype.goBackToLogin = function () {
        document.querySelector('.login__inner').scrollTo({ top: 0, behavior: 'smooth' });
        this.phoneFormActive = true;
        this.registrationPartActive = false;
        this.isRegistrationUpdate = false;
        this.userRegistrationUpdateData = null;
        this.authType = '';
        this.authCode = null;
        this.pinForm.reset();
        this.resetPinInput();
    };
    LoginComponent.prototype.resetPinInput = function () {
        this.pinForm.get('digits').clear();
        for (var i = 0; i < this.numOfDigits; i++) {
            this.pinForm.get('digits').push(this.formBuilder.control(null, Validators.required));
        }
    };
    LoginComponent.prototype.logout = function () {
        console.log("login logout()");
        this.auth.logout();
        //this.router.navigate(['/']);
        this.goBackToLogin();
        this.showGamesSelection = false;
        this.popupComponent.closePopup();
        if (this.phoneForm) {
            this.phoneForm.controls.phone.setValue(null);
        }
        else {
            this.phoneFormInit();
        }
        this.resetPinInput();
        this.pointId = null;
    };
    LoginComponent.prototype.upgradeApp = function () {
        this.appVersionService.upgradeApp();
    };
    LoginComponent.prototype.openAboutSite = function () {
        this.menu = false;
        var link = this.partnerService.getDomainHome();
        window.open(link, '_blank');
    };
    LoginComponent.prototype.contactUs = function () {
        this.menu = false;
        this.contactUsPopup.show = true;
    };
    LoginComponent.prototype.hideContactUs = function () {
        this.contactUsPopup.show = false;
    };
    LoginComponent.prototype.isLoginBtnDisabled = function () {
        if (this.isLoginTypeEmail()) {
            return this.phoneForm.controls.email.invalid;
        }
        if (this.isLoginTypePhone()) {
            return this.phoneForm.controls.phone.invalid;
        }
    };
    LoginComponent.prototype.changeLoginType = function (type) {
        this.loginType = type;
    };
    LoginComponent.prototype.isLoginTypePhone = function () {
        return this.loginType === 'phone';
    };
    LoginComponent.prototype.isLoginTypeEmail = function () {
        return this.loginType === 'email';
    };
    LoginComponent.prototype.isSecondStepAuthSMSPin = function () {
        return this.authType === AUTH_TYPE.sms;
    };
    LoginComponent.prototype.isSecondStepAuthSMSPinPassword = function () {
        return this.authType === AUTH_TYPE.smsPinPassword;
    };
    LoginComponent.prototype.isSecondStepAuthAuthPin = function () {
        return this.authType === AUTH_TYPE.authPin;
    };
    LoginComponent.prototype.isSecondStepAuthAuthPinPassword = function () {
        return this.authType === AUTH_TYPE.authPinPassword;
    };
    LoginComponent.prototype.isSecondStepPassword = function () {
        return this.authType === AUTH_TYPE.password;
    };
    LoginComponent.prototype.onForgotPassword = function () {
        var _this = this;
        var phone = this.isLoginTypePhone() ? this.phoneForm.controls.phone.value : null;
        var email = this.isLoginTypeEmail() ? this.phoneForm.controls.email.value : null;
        this.authenticationService.forgotPassword(email, phone).subscribe(function (data) {
            try {
                if (data.status) {
                    _this.goBackToLogin();
                    _this.popupComponent.showPopupSuccess({ text: data.message });
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    LoginComponent.prototype.toggleInputType = function () {
        if (this.isSecondStepAuthAuthPin() || this.isSecondStepAuthAuthPinPassword()) {
            this.authType = this.isSecondStepAuthAuthPin() ? AUTH_TYPE.authPinPassword : AUTH_TYPE.authPin;
            this.authCode = this.isSecondStepAuthAuthPin() ? AUTH_CODES.showAuthPin : AUTH_CODES.showPassword;
        }
        else if (this.isSecondStepAuthSMSPin() || this.isSecondStepAuthSMSPinPassword()) {
            this.authType = this.isSecondStepAuthSMSPin() ? AUTH_TYPE.smsPinPassword : AUTH_TYPE.sms;
            this.authCode = this.isSecondStepAuthSMSPin() ? AUTH_CODES.showSMSPin : AUTH_CODES.showPassword;
        }
        // this.authType = this.isSecondStepAuthAuthPin() ? AUTH_TYPE.authPinPassword : this.isSecondStepAuthAuthPinPassword() ? AUTH_TYPE.authPin :
        //                 this.isSecondStepAuthSMSPin() ? AUTH_TYPE.smsPinPassword : AUTH_TYPE.sms;
        if (this.isSecondStepAuthAuthPinPassword() || this.isSecondStepAuthSMSPinPassword()) {
            this.focusFirstInput();
        }
        else {
            this.focusPinInputs();
        }
    };
    LoginComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginComponent_Factory() { return new LoginComponent(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.AppVersionService), i0.ɵɵinject(i3.AuthenticationService), i0.ɵɵinject(i4.LoginService), i0.ɵɵinject(i5.LoaderService), i0.ɵɵinject(i6.PhoneMaskService), i0.ɵɵinject(i7.Router), i0.ɵɵinject(i7.ActivatedRoute), i0.ɵɵinject(i3.AuthenticationService), i0.ɵɵinject(i8.LanguageService), i0.ɵɵinject(i9.ContentDisplayingService), i0.ɵɵinject(i10.AppPromptService), i0.ɵɵinject(i11.PartnerService), i0.ɵɵinject(i12.PopupComponent)); }, token: LoginComponent, providedIn: "root" });
    return LoginComponent;
}());
export { LoginComponent };
