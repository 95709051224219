import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PhoneMaskService} from "../../service/phoneMask.service";
import {PopupComponent} from "../popup/popup.component";
import {SENETPRO_REDEEM_METHODS} from "../../pages/perks/perks.config";
import {CurrencyService} from "../../modules/currency/currency.service";
import {LanguageService} from "../../modules/language/language.service";

@Component({
    selector: 'app-popup-prizes-senetpro-confirm',
    templateUrl: './popup-prizes-senetpro-confirm.component.html',
    styleUrls: ['./popup-prizes-senetpro-confirm.component.scss']
})
export class PopupPrizesSenetproConfirmComponent implements OnInit {
    @Input() popupInfo;
    @Input() isPopup = true;
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() changeHandle: EventEmitter<{type: string, handle: string, fees?: number, total?: number}> = new EventEmitter();
    @Output() confirm: EventEmitter<any> = new EventEmitter();

    form: FormGroup;
    formDeliverySpeed: FormGroup;

    moreThenAvailablePayout = false;
    moreThenAvailableText = '';

    selectedMethod;

    PRIZES_METHODS = SENETPRO_REDEEM_METHODS;
    isTotalAmountCorrect = false;
    totalAmount = 0;
    phoneMask;

    deliveryPercent;
    minAmount;
    maxAmount;
    showSameDay;
    constructor(private loaderService: LoaderService,
                private phoneMaskService: PhoneMaskService,
                private currencyService: CurrencyService,
                private languageService: LanguageService,
                private popupComponent: PopupComponent,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            handle: [null],
            phone: [null],
            phoneVenmo: [{value: null}, Validators.required],
            amount: [null, Validators.required],
            quantity: [null]
        });

        this.formDeliverySpeed = this.formBuilder.group({
            speed: ['nextDay', Validators.required]
        });

        this.phoneMask = this.phoneMaskService.setPhoneMask();
    }

    ngOnChanges(changes) {
        if (changes.popupInfo.currentValue.show) {
            if (this.popupInfo.providers) {
                this.selectedMethod = this.popupInfo.providers[0];
                this.form.controls.handle.setValue(this.selectedMethod);
            }
           if (this.popupInfo.type === this.PRIZES_METHODS.venmo) {
                this.form.controls.phoneVenmo.setValue(this.popupInfo.handle);
           }
            if (this.popupInfo.type === this.PRIZES_METHODS.games) {
                this.form.controls.amount.setValue(this.popupInfo.enteredDebit);
            }

            if (this.popupInfo.type === this.PRIZES_METHODS.paynote) {
                this.form.controls.handle.setValue(this.popupInfo.handle);
            }

            if ((this.popupInfo.type === this.PRIZES_METHODS.venmo) || (this.popupInfo.type === this.PRIZES_METHODS.paypal)) {
                const available = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
                this.showSameDay = this.popupInfo.sameDayShow;
                this.minAmount = this.popupInfo.amountMin ? this.popupInfo.amountMin : 1;
                this.maxAmount = this.popupInfo.amountMax ? this.popupInfo.amountMax : available;
                this.deliveryPercent = this.popupInfo.sameDay && this.popupInfo.sameDay.fees && this.popupInfo.sameDay.fees.percent ? this.popupInfo.sameDay.fees.percent : null;
            }
        } else {
            if (this.form) {
                this.form.reset();
            }
            this.isTotalAmountCorrect = false;
            this.totalAmount = 0;
            this.moreThenAvailablePayout = false;
        }
    }

    setPaypalVerified($event) {
        this.popupInfo.paypalAddress = true;
        this.updateHandle(this.popupInfo.type, $event);
    }

    setVenmoVerified($event) {
        this.popupInfo.venmoAddress = true;
        this.updateHandle(this.popupInfo.type, $event);
        this.form.controls.phoneVenmo.setValue($event);
    }

    updateHandle(type, handle) {
        this.form.controls.handle.setValue(handle);
        this.changeHandle.emit({type: type, handle: handle});
    }

    closePopup(full = false) {
        this.formDeliverySpeed.controls.speed.setValue('nextDay');
        this.close.emit(full);
    }

    onSubmitForm() {
        if (this.isOneOfGiftCards()) {
            this.confirm.emit({amount: this.form.controls.amount.value, type: this.popupInfo.type, qty: this.form.controls.quantity.value});
        } else {
            if (this.popupInfo.type === this.PRIZES_METHODS.paynote) {
                this.changeHandle.emit({type: this.popupInfo.type, handle: this.form.controls.handle.value});
            }
            if (this.popupInfo.type === this.PRIZES_METHODS.paypal) {
                this.changeHandle.emit({type: this.popupInfo.type, handle: this.form.controls.handle.value, fees: this.getFees(), total: this.getTotal()});
            }
            if (this.popupInfo.type === this.PRIZES_METHODS.venmo) {
                this.changeHandle.emit({type: this.popupInfo.type, handle: this.form.controls.phoneVenmo.value, fees: this.getFees(), total: this.getTotal()});
            }
            this.confirm.emit({amount: this.form.controls.amount.value, type: this.popupInfo.type});
        }
    }

    onInputAmount() {
        if (this.isPaypalOrVenmo()) {
            const avaliable = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
            const avaliableAndMax = avaliable <= this.maxAmount ? avaliable : this.maxAmount;
            const entered = Number.parseFloat(this.form.controls.amount.value);

            if (avaliableAndMax < entered) {
                this.form.controls.amount.setValue(String(avaliableAndMax));
                this.moreThenAvailablePayout = true;
                if (avaliableAndMax === this.maxAmount) {
                    this.moreThenAvailableText = this.languageService.getStringFirstCharCapital('max') + ' ' + this.languageService.getString('available') + ' ' + this.formatCurrency(avaliableAndMax);
                } else {
                    this.moreThenAvailableText = this.languageService.getString('available') + ' ' + this.formatCurrency(avaliableAndMax);
                }
            } else {
                if (entered < this.minAmount) {
                    this.moreThenAvailablePayout = true;
                    this.moreThenAvailableText = this.languageService.getStringFirstCharCapital('min') + ' ' + this.languageService.getString('available') + ' ' + this.formatCurrency(this.minAmount);
                } else {
                    this.moreThenAvailablePayout = false;
                    this.moreThenAvailableText = '';
                }
            }
        } else {
            const avaliable = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
            const entered = Number.parseFloat(this.form.controls.amount.value);

            if (avaliable < entered) {
                this.form.controls.amount.setValue(String(avaliable));
                this.moreThenAvailablePayout = true;
            } else {
                this.moreThenAvailablePayout = false;
            }
        }
    }

    onInputQuantity() {
        const avaliable = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
        this.totalAmount = this.form.controls.amount.value * +this.form.controls.quantity.value;
        this.isTotalAmountCorrect = this.totalAmount <= avaliable;
    }

    isOneOfGiftCards(): boolean {
        return this.popupInfo.type === this.PRIZES_METHODS.giftCardVisa || this.popupInfo.type === this.PRIZES_METHODS.giftCardAmazon
    }

    selectAmount(item) {
        this.form.controls.amount.setValue(item);
        // this.onInputAmount();
        this.onInputQuantity();
    }

    formatCurrency(amount) {
        return this.currencyService.formatCurrency(amount);
    }

    getFees(): number {
        if ((this.formDeliverySpeed.controls.speed.value === 'sameDay') && this.form && this.form.controls.amount.value && this.deliveryPercent) {
            return +(+this.form.controls.amount.value / 100 * this.deliveryPercent).toFixed(2);
        }

        return 0;
    }

    getTotal(): number {
        if (this.form && this.form.controls.amount.value) {
            return +(+this.form.controls.amount.value - this.getFees()).toFixed(2);
        }
        return 0;
    }

    isPaypalOrVenmo(): boolean {
        return (this.popupInfo.type === this.PRIZES_METHODS.venmo) || (this.popupInfo.type === this.PRIZES_METHODS.paypal);
    }
}
