import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from '../modules/auth/_guards/auth.guard';
import { ShopComponent } from "./shop/shop.component";
import { ContactsFormComponent } from "./contacts-form/contacts-form.component";
import { SupportComponent } from "./support/support.component";
import { CheckoutComponent } from "./checkout/checkout.component";
import { PerksComponent } from "./perks/perks.component";
import { AccountComponent } from "./account/account.component";
import { CommissionComponent } from "./commission/commission.component";
import { SenetproComponent } from "./promo/senetpro/senetpro.component";
import { PartnerComponent } from "./partner/partner.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
var ɵ0 = function () { return import("./terms-and-conditions/terms-and-conditions.module.ngfactory").then(function (m) { return m.TermsAndConditionsModuleNgFactory; }); }, ɵ1 = function () { return import("./store/store.module.ngfactory").then(function (m) { return m.StoreModuleNgFactory; }); };
var routes = [
    {
        path: '', component: PartnerComponent, children: [
            { path: '', component: LoginComponent },
            // {path: 'test-cash-app', component: TestCashAppComponent},
            { path: 'senetpro', component: SenetproComponent },
            { path: 'password/restore', component: ResetPasswordComponent },
            { path: 'email/verify', component: VerifyEmailComponent },
            // { path: 'games/:id', canActivate: [AuthGuard],
            //   loadChildren: () => import('./games-playground/games-playground.module').then(m => m.GamesPlaygroundModule) },
            { path: 'home', component: HomeComponent, children: [
                    // {path: 'home', component: HomeComponent, children: [
                    //         {path: 'test-cash-app-checkout', component: CheckoutCashAppComponent},
                    //         {path: 'terms-and-conditions', component: TermsAndConditionsComponent},
                    { path: 'terms-and-conditions', loadChildren: ɵ0 },
                    { path: 'contacts', component: ContactsFormComponent },
                    { path: 'shop', component: ShopComponent, canActivate: [AuthGuard] },
                    { path: 'checkout', component: CheckoutComponent, canActivate: [AuthGuard] },
                    { path: 'perks', component: PerksComponent, canActivate: [AuthGuard] },
                    { path: 'support', component: SupportComponent, canActivate: [AuthGuard] },
                    { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
                    { path: 'commission', component: CommissionComponent, canActivate: [AuthGuard] },
                    { path: 'store', loadChildren: ɵ1 },
                ] },
            { path: '**', redirectTo: '', pathMatch: 'full' }
        ]
    }
];
var PagesModule = /** @class */ (function () {
    function PagesModule() {
    }
    return PagesModule;
}());
export { PagesModule };
export { ɵ0, ɵ1 };
