<div class="login__forms"  [class.is-blurred]="popupTransactions.show || popupConfirmInfo.show || popupChannels.show">
    <div class="login__tab login__tab--no-rewards-block">

        <ng-container *ngIf="showAdditionalContent()">
            <app-balance-heading></app-balance-heading>
        </ng-container>

        <div class="page-block" *ngIf="showAdditionalContent()">
            <div class="gl-page-title gl-page-title--icon c-accent">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
                {{'redeem' | languageTranslate}}
            </div>
            <div class="gl-subtitle gl-subtitle--opacity">
<!--                Select the sweeps below to redeem. All redemptions will go to your Spend Account.-->
                {{'redeemBlockTitle' | languageTranslate}}
            </div>
            <form [formGroup]="pageForm" class="login__form">

                <div class="form-simple__item">
                    <mat-form-field>
                        <mat-select #methodsSelect
                                    formControlName="method"
                                    (valueChange)="onProviderChange($event)"
                                    [(value)]="selectedProvider" panelClass="my-select-panel-border-yellow">
                            <mat-select-trigger>
                                {{methodsSelect?.value?.name}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let method of providers" [value]="method">
                                {{method.name | uppercase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="available-balance">
                    {{'youHave' | languageTranslate}} <span>{{formatCurrency(availableBalance)}}</span> {{'availableOn' | languageTranslate}} {{methodsSelect?.value?.name | titlecase}}.
                </div>

                <div class="form-simple__item">
                    <input mask="separator.0"
                           thousandSeparator=","
                           (input)="onInputAmount()"
                           formControlName="amount"
                           placeholder="Amount"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <div class="form-simple__available" [class.is-active]="moreThenAvailablePayout">
                        {{'available' | languageTranslate}} {{formatCurrency(availableBalance)}}
                    </div>
                </div>

                <div class="shop-btns shop-btns--no-btn-mb">
                    <button class="button"
                            (click)="this.onSubmitRedeem(pageForm.controls.amount.value)"
                            [disabled]="!pageForm.controls.amount.value"
                            type="button">
                        {{'redeem' | languageTranslate}}
                    </button>
                    <a class="button"
                            type="button"
                            *ngIf="isSupportShown()"
                            [routerLink]="RouterPaths.support">
                        {{'support' | languageTranslate}}
                    </a>
                </div>
            </form>
        </div>

        <div class="page-block" [class.page-block--no-mt]="!showAdditionalContent()">
            <div class="gl-page-title gl-page-title--icon c-accent">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
                {{'spendAccount' | languageTranslate}}
                <span class="gl-page-title__balance">{{formatCurrency(redeemedVal)}}</span>
            </div>
            <div>
<!--                This is your total, accumulated withdrawal balance. All redeems and all withdrawals are available for your review in the table below. Use the accumulated points on any of your favorite sweeps, buy more gift cards, send them to a friend, or sell them at any time and get paid!-->
               <span class="gl-subtitle gl-subtitle--opacity">{{'spendAccountBlockTitle' | languageTranslate}}</span>
                <ng-container *ngIf="showAdditionalContent()">
                        <span class="gl-subtitle gl-subtitle--opacity accent" style="opacity: 1">
                            {{'spendAccountBlockTitleAdditional' | languageTranslate}}
                        </span>
                </ng-container>
            </div>

            <div class="dropdown" style="padding-bottom: 0; text-align: center">
                <app-senetpro-transactions [items]="transactionsList"
                                           [isPerks]="true"
                                           [processingItems]="accountHistoryProcessing" [isStickyHeader]="false"></app-senetpro-transactions>

                <button (click)="showTransactionsPopup()"
                        class="button btn-show-transactions"
                        *ngIf="showMoreTransactionsBtn"> {{'showMore' | languageTranslate}}</button>
            </div>

            <div class="shop-btns shop-btns--no-btn-mb"
                 [ngStyle]="{justifyContent: showSellPoints && showAdditionalContent() ? 'space-between' : 'center'}">
                <button class="button"
                        *ngIf="showAdditionalContent()"
                        type="button"
                        (click)="showChannelsPopup(POPUP_CHANNELS_TYPE.use)">
                    {{'getSweeps' | languageTranslate}}
                </button>
                <button class="button"
                        *ngIf="showSellPoints"
                        type="button"
                        (click)="showChannelsPopup(POPUP_CHANNELS_TYPE.sell)">
                    {{'withdraw' | languageTranslate}}
                </button>
            </div>
        </div>
    </div>
</div>

<app-popup-senetpro-transactions [popupInfo]="popupTransactions"
                                 (close)="closeTransactionsPopup()">
</app-popup-senetpro-transactions>

<app-popup-channels [popupInfo]="popupChannels"
                    (close)="closeChannelsPopup($event)"></app-popup-channels>
